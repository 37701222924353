import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      current_password: null,
      new_password: null,
      confirm_password: null,
    };
  },
  mixins: [validationMixin],
  validations: {
    current_password: { required, minLength: minLength(4) },
    new_password: { required, minLength: minLength(4) },
    confirm_password: { required, minLength: minLength(4) },
  },
  methods: {
    async onSubmitForm() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      if (this.current_password === this.new_password) {
        this.onExceptionHandler("รหัสผ่านใหม่จะต้องไม่ซ้ำกับรหัสผ่านเก่า");
        return;
      }

      if (this.confirm_password !== this.new_password) {
        this.onExceptionHandler("รหัสผ่านใหม่จะต้องเหมือนกันทั้ง 2 ช่อง");
        return;
      }

      this.loading = true;
      this.busy = true;

      const formData = new FormData();
      formData.append("current_password", this.current_password);
      formData.append("new_password", this.new_password);

      try {
        const result = await this.$axios.post(
          this.$host + "/api/v1/change-password",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (result) {
          this.current_password = null;
          this.new_password = null;
          this.confirm_password = null;

          this.$nextTick(() => {
            this.$v.$reset();
          });

          this.$swal({
            width: "29rem",
            padding: "1.825em",
            html:
              '<div class="d-flex flex-row align-items-center">' +
              '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>' +
              '<div class="d-flex flex-column">' +
              '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>' +
              `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>` +
              "</div>" +
              "</div>",
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: "swal2-custom-rounded",
              closeButton: "text-3xl swal2-custom-close",
              actions: "justify-content-end",
              contant: "d-flex flex-row justify-content-around px-2",
              title: "d-none",
              confirmButton:
                "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton:
                "btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            },
            showCancelButton: false,
            confirmButtonText: "ตกลง",
            cancelButtonText: "ยกเลิก",
            showCloseButton: true,
          }).then((result) => {
            if(result){
              localStorage.Sangthai_isLoggedIn = false;
              localStorage.Sangthai_access_token = null;
              localStorage.Sangthai_expires_in = null;
              localStorage.Sangthai_refresh_token = null;
        
              this.$router
                .push({
                  path: "/",
                })
                .catch((error) => {
                  console.info(error.message);
                });
            }
          });
        }
      } catch (error) {
        this.busy = false;
        this.loading = false;
        this.onExceptionHandler(error.response.data.message);
      }
    },
  },
};
