import Drawer from '@/components/drawers'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import Loading from "@/components/widgets/Loading.vue"
import Vue from 'vue'

export default {
  name: 'user-update',
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      drawerOpened: false,
      preview: null,
      showFileInput: true,
      form: {
        _id: null,
        image: null,
        firstname: null,
        lastname: null,
        employee_code: null,
        email: null,
        phone_number: null,
        facebook: null,
        line: null,
        gender: 1,
        role: null,
        note: null
      }
    }
  },
  components: {
    Loading,
    Drawer
  },
  mixins: [validationMixin],
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      employee_code: { required },
      phone_number: { required }
    },
  },
  watch: {
    'opened'(n, o) {
      if (n) {
        this.showFileInput = true
        this.drawerOpened = n
      }
    }
  },
  methods: {
    resetForm () {
      this.form.firstname = null
      this.form.lastname = null
      this.form.employee_code = null
      this.form.email = null
      this.form.phone_number = null
      this.form.facebook = null
      this.form.line = null
      this.form.gender = 1
      this.form.line = null
      this.form.role = null
      this.form.note = null

      this.$nextTick(() => {
        this.$v.$reset();
      })

      this.onFileRemove()
    },
    formBuilder(obj) {
      this.form._id = obj.data?.uuid ?? ''
      this.form.firstname = obj.data?.firstname ?? ''
      this.form.lastname = obj.data?.lastname ?? ''
      this.form.employee_code = obj.data?.employee_code ?? ''
      this.form.email = obj.data?.email ?? ''
      this.form.phone_number = obj.data?.phone_number ?? ''
      this.form.facebook = obj.data?.facebook ?? ''
      this.form.line = obj.data?.line ?? ''
      this.form.gender = obj.data?.gender ?? 1
      this.form.role = obj.data?.role ?? ''
      this.form.note = obj.data?.note ?? ''

      this.$v.form.$touch()
    },
    onCloseDrawer(event) {
      this.drawerOpened = false
      this.resetForm()
      this.$emit('close-event', { 
        status: true
      })
    },
    onFileRemove() {
      this.showFileInput = false
      Vue.nextTick(() => {
        this.preview = null
        this.showFileInput = !this.showFileInput
      })
    },
    onFileTriger() {
      const imageRef = this.$refs.image
      if (imageRef) {
        imageRef.click()
      }
    },
    onFileChange(event) {
      const file = event.target.files[0]
      this.form.image = file
      this.onFIletoBase64(file).then(file => {
        this.preview = file
      }).catch(error => {
        this.onExceptionHandler(error.message)
      })
    },
    onFIletoBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async onInitData() {
      const param = {
        _id: this.id
      }
      const result = await this.$store.dispatch('Customers/get', param, { root: true })
      if (result.status === 200) {
        this.formBuilder(result)
      } else {
        this.onExceptionHandler()
      }
    },
    async onSubmitForm() {
      // this.$v.form.$touch()
      // if (this.$v.form.$anyError) {
      //   return
      // }
      // this.loading = true
      // const formData = new FormData()
      // if (this.form.image) {
      //   formData.append('image', this.form.image)
      // }
      // formData.append('code', this.form.code?? '')
      // formData.append('name', this.form.name?? '')
      // formData.append('phone_number', this.form.phone_number?? '')
      // formData.append('email', this.form.email?? '')
      // formData.append('line', this.form.line?? '')
      // formData.append('facebook', this.form.facebook?? '')
      // formData.append('address', this.form.addr?? '')
      // formData.append('company_name', this.form.company?? '')
      // formData.append('tax_id', this.form.tax_no?? '')
      // formData.append('description', this.form.description?? '')

      // let result = {}
      // const body = {
      //   _id: this.id,
      //   formData: formData
      // }
      // result = await this.$store.dispatch('Customers/update', body, { root: true })
      // this.loading = false    
      // setTimeout(() => {
      //   if (result.status === 201 || result.status === 200) {
      //     this.resetForm()
      //     this.$swal({
      //       title: "แจ้งเตือน",
      //       text: result.data?.message ?? "บันทึกข้อมูลเรียบร้อยแล้ว",
      //       type: "info",
      //       width: "23rem",
      //       allowOutsideClick: false,
      //       focusConfirm: false,
      //       customClass: {
      //         title: "d-none",
      //         confirmButton: "btn btn-info rounded-lg text-white font-prompt-light text-sm font-normal"
      //       },
      //       showCancelButton: false,
      //       confirmButtonText: "ตกลง",
      //       showCloseButton: true,
      //     }).then(confirm => {
      //       this.onCloseDrawer()
      //     })
      //   } else {
      //     this.onExceptionHandler()
      //   }
      // }, 500)

      this.resetForm()
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
          `<span class="text-base text-left text-gray-500">บันทึกข้อมูลเรียบร้อยแล้ว</span>`+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          showCancelButton: false,
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          showCloseButton: true,
      }).then(result => {
        this.onCloseDrawer()
      })
    }
  }
}