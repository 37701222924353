import Vue from "vue";
import myUpload from 'vue-image-crop-upload/upload-2.vue';

export default {
  components: {
    'my-upload': myUpload
  },
  props: {
    pic: null,
  },
  data () {
    return {
      url: `${Vue.prototype.$host}/api/v1/upload-signature`,
			show: false,
			params: {
			},
			headers: {
				Authorization: `Bearer ${localStorage.Sangthai_access_token}`,
        // 'Content-Type': 'image/png'
			},
			imgDataUrl: '' // the datebase64 url of created image
    };
  },
  mounted() {
    this.loadSignature()
  },
  methods: {
    async loadSignature(){
      try {
        this.loading = true
        const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/signature`)

        this.loading = false
        if(result.status === 200) {
          const finalImageURL = `${Vue.prototype.$host}/${result.data.data}`
          if(result.data.data){
            this.imgDataUrl =  finalImageURL
          }
        }
      } catch (error) {
        this.loading = false
        let message = error.response? error.response.data.message: error.message
        this.onExceptionHandler(message)
      }
    },
    toggleShow() {
      this.show = !this.show;
    },
          /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field){
      console.log('-------- crop success --------');
      this.imgDataUrl = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field){
      console.log('-------- upload success --------');
      console.log(jsonData);
      console.log('field: ' + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field){
      console.log('-------- upload fail --------');
      console.log(status);
      console.log('field: ' + field);
    },
  },
};
